@mixin layoutLoginColor($c) {
    .login-bg > svg {
        fill: $c;
    }
    .login-page {
        > ._form {
            > ._title {
                color: wcolor($c, 55%);
            }

            > ._version {
                color: wcolor($c, 75%);
            }

            .btn {
                border-color: bcolor($c, 90%);
                background-color: wcolor($c, 75%);

                &:hover {
                    background-color: wcolor($c, 55%);
                }

                &:focus {
                    box-shadow: 0 0 0 0.2rem bcolor($c, 75%);
                }
            }
        }
    }
}

.layout {
    &.site_main {
        @include layoutLoginColor($mainColor);
    }

    &.site_north {
        @include layoutLoginColor($blue);
    }
}

.login-container {
    position: relative;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.login-bg {
    height: 200px;
    position: relative;
}


.login-page {
    flex: 1;
    display: flex;
    border-radius: .5rem;
    padding: 1.5rem 1rem;
    z-index: 1000;

    align-items: center;
    justify-content: center;

    > ._image {
        width: 500px;

        img {
            max-width: 100%;
        }
    }

    > ._form {
        width: 300px;
        margin-left: 50px;
        margin-right: 200px;

        > ._title {
            text-align: center;
            font-size: 20pt;
            margin-bottom: .25rem;
            text-transform: uppercase;
        }

        > ._version {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 10pt;
        }

        .btn {
            margin-top: 2rem;
            color: $white;
            padding: 1rem;
            border-radius: .5rem;

            &:focus {
                outline: 0;
            }
        }
    }
}
