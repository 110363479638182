.document-notice-test-box {
    @apply pt-4 pb-4;
    border-bottom: 1px dashed wcolor($grey-blue, 50%);
    margin-bottom: 1rem;

    ._anwser {
        @apply flex mb-4 items-center;
        ._label {
            width: 75px;
            margin-right: .5rem;
            text-align: right;
            color: $grey-blue;
        }

        .un-inputbox {
            @apply flex-1 mb-0;
        }
    }
}

.document-plan-subinput {
    display: flex;
    margin-top: .25rem;

    > ._label {
        width: 60px;
        font-size: .8rem;
        font-weight: normal;
        text-align: right;
        margin-right: .5rem;
        color: $grey-blue;
    }

    > .inputbox {
        flex: 1;
    }
}

.document-entry-priority {
    @apply flex flex-wrap items-center;
    > ._item {
        @apply rounded bg-grey-blue-400 px-2 text-sm text-white;
        &.-r {
            @apply bg-green-500;
        }

        &.-rw {
            @apply bg-red-500;
        }

        + ._item {
            @apply ml-2;
        }
    }
}

////////////////////

._select-state {
    width: 100px;
}

.un-list-line-driver {
    .input-select {
        margin-top: -3px !important;

        .s__control {
            min-height: 20px;
        }

        .s__clear-indicator {
            padding: 0 4px;
        }

        .s__dropdown-indicator, .s__indicator-separator {
            display: none;
        }

        .s__value-container {
            padding-top: 0;
            padding-bottom: 0;

            > div {
                padding: 0;
            }
        }
    }
}


.btn.btn-map {
    @apply w-10 h-10 bg-white flex items-center justify-center mr-2.5 rounded-sm text-xl;
}


.ivms-tooltip {
    position: fixed;
    width: 200px;
    z-index: 1400;
    height: 1px;
    display: none;

    > ._content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        padding: .25rem;
        background-color: $black;
        border-radius: .25rem;
        min-height: 50px;
        color: $white;
        font-size: 9pt;

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: $black transparent transparent transparent;
            bottom: -20px;
            left: 50%;
            margin-left: -10px;
        }

        ._item {
            padding-left: .5rem;
            margin-top: .25rem;

            ._time {
                font-size: 8pt;
            }
        }
    }
}


.ivms-view {
    &.-new {
        .ivms-view-header {
            margin-top: 0;
            margin-bottom: .5rem;
        }

        .ivms-view-driver-sum {
            > ._item {
                > ._name {
                    padding: .125rem 1rem;
                }

                > ._sum {
                    > span {
                        &._label {
                            font-weight: normal;
                            width: auto;
                        }

                        font-size: 8pt;
                        width: auto;
                    }
                }
            }
        }
    }

    &.-time {
        .ivms-view-driver-sum {
            @apply mt-2 pt-4 mb-0;
            > ._item {
                @apply mb-0;
            }
        }
    }

    .modal-content {
        min-height: 400px;
    }
}

.ivms-view-map {
    height: 400px;
    position: relative;
}

.ivms-view-box {
    @apply border;
    padding: .25rem;
    width: 180px;

    margin-right: .5rem;
    margin-bottom: .5rem;

    > ._header {
        font-size: 10pt;
        text-align: center;
        color: $grey-blue;
    }

    > ._item {
        display: flex;
        margin: .25rem;

        > ._label {
            font-size: 10pt;
        }

        > ._value {
            margin-left: auto;
        }
    }
}


.ivms-view-sumtime {
    display: flex;

    > ._item {
        + ._item {
            margin-left: .5rem;
        }

        border: 1px solid wcolor($grey-blue, 50%);
        border-radius: .25rem;
        background-color: wcolor($grey-blue, 5%);

        padding: .25rem .5rem;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        font-size: 10pt;

        &.-green {
            border-color: $green;
            background-color: wcolor($green, 10%);

            > ._label {
                color: $green;
            }
        }

        > ._label {
            margin-right: auto;
            color: $grey-blue;
            width: 50%;
        }

        > ._value {
            margin-left: .5rem;
        }

        > ._value-cal {
            @apply ml-2 text-blue-700;
        }

        + .ivms-time-box {
            margin-left: 1rem;
        }
    }
}


.ivms-view-status {
    > ._name {
        text-align: center;
        font-size: 14pt;
    }

    > ._time {
        font-size: 10pt;
        color: $grey-blue;
    }
}

$driver-sum-name-width: 100px;
.ivms-view-driver-sum {
    @apply relative pt-8 mt-12 mb-4;

    > ._line {
        @apply absolute inset-y-0 right-0 z-40;
        left: $driver-sum-name-width;

        > div {
            @apply absolute inset-y-0;
            border-left: 1px dashed $red;

            &._right {
                > span {
                    @apply border border-red-500 right-0 left-auto rounded-l rounded-r-none;
                }
            }

            > span {
                @apply absolute bg-red-400 text-xs text-white py-0.5 px-2 -top-5 -left-px rounded-r;
                white-space: nowrap;
            }
        }
    }

    > ._item {
        @apply flex z-30 bg-grey-blue-50 mb-2 pb-4;
        > ._name {
            @apply truncate py-2 px-4 text-sm bg-grey-blue-50;
            width: $driver-sum-name-width;
        }

        > ._slot {
            @apply flex-1 relative bg-grey-blue-100;
            > ._item {
                @apply absolute top-1 bottom-1 text-white text-center text-xs bg-grey-blue-500 opacity-50 cursor-pointer;

                &.-run {
                    @apply bg-green-500;
                }

                &.-cruise {
                    @apply bg-orange-500;
                }

                &.-park {
                    @apply bg-red-500;
                }

                &.-stop {
                    @apply bg-blue-500;
                }

                &:hover {
                    opacity: 1;
                }

                > ._trailer {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    color: #000;
                    width: 22px;
                    margin-left: -11px;
                    text-align: center;
                    overflow: hidden;
                }
            }
        }

        > ._sum {
            position: absolute;
            left: $driver-sum-name-width;
            right: 0;
            background-color: $white;
            bottom: 0;
            font-size: 10pt;
            display: flex;

            > span {
                margin-left: .5rem;
                width: 85px;
                cursor: default;
                padding: .125rem .25rem;

                &._label {
                    font-weight: bold;
                    width: auto;
                }

                &:hover {
                    background-color: wcolor($yellow, 20%);
                }

                > .un-icon {
                    margin-right: .25rem;
                }
            }
        }
    }
}

.ivms-driver-sum {
    @apply relative pt-1 mt-6 mb-4;

    > ._line {
        @apply absolute inset-y-0 right-0;
        left: $driver-sum-name-width;
        z-index: 30;

        > div {
            @apply absolute inset-y-0;
            border-left: 1px dashed $red;

            &._right {
                > span {
                    @apply border border-red-500 right-0 left-auto rounded-l rounded-r-none;
                }
            }

            > span {
                @apply absolute bg-red-400 text-xs text-white py-0.5 px-2 -top-5 -left-px rounded-r;
                white-space: nowrap;
            }
        }
    }

    > ._item {
        z-index: 31;
        //background-color: wcolor($grey-blue, 15%);
        margin-bottom: .5rem;
        position: relative;
        display: flex;
        padding-bottom: 24px;

        > ._name {
            @apply truncate px-4 py-1;
            font-size: 10pt;
            width: $driver-sum-name-width;
            background-color: wcolor($grey-blue, 5%);
        }

        > ._slot {
            @apply flex-1 relative bg-grey-blue-100;
            > ._item {
                @apply absolute bg-grey-blue-500 opacity-50 cursor-pointer text-white text-center text-xs top-0 bottom-0;
                &.-run {
                    @apply bg-green-500;
                }

                &.-cruise {
                    @apply bg-orange-500;
                }

                &.-park {
                    @apply bg-red-500;
                }

                &.-stop {
                    @apply bg-blue-500;
                }

                &:hover {
                    opacity: 1;
                }

                > ._trailer {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    color: #000;
                    width: 22px;
                    margin-left: -11px;
                    text-align: center;
                    overflow: hidden;
                }
            }
        }

        > ._sum {
            position: absolute;
            left: $driver-sum-name-width;
            right: 0;
            background-color: $white;
            bottom: 0;
            font-size: 10pt;
            display: flex;

            > span {
                margin-left: .5rem;
                width: 85px;
                cursor: default;
                padding: .125rem .25rem;

                &._label {
                    font-weight: bold;
                    width: auto;
                }

                &:hover {
                    background-color: wcolor($yellow, 20%);
                }

                > .un-icon {
                    margin-right: .25rem;
                }
            }
        }
    }
}


.un-list .ivms-view-driver-sum {
    padding-top: 0;
    margin-top: -.375rem;
    margin-bottom: -.375rem;

    > ._item {
        height: 31px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    > ._line {
        left: 0;
    }
}

.un-list-head .ivms-view-driver-sum {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;

    > ._line {
        left: 0;

        > div > span {
            line-height: 1rem;
        }
    }
}

.ivms-view-driver-chart {
    display: flex;
    justify-content: flex-end;
    margin-top: .25rem;
    font-size: 10pt;

    > div {
        margin-left: .5rem;
    }
}

.ivms-view-btn-route {
    display: flex;
    flex-wrap: wrap;

    > .button {
        margin-right: .25rem;
        margin-bottom: .25rem;
    }
}

.ivms-route-box {
    display: flex;

    > .un-icon {
        font-size: 2rem;
        width: 2.5rem;
        text-align: center;
        margin-right: .5rem;
    }

    > ._text {
        flex: 1;

        > ._time {
            font-size: 11pt;
            color: $grey-blue;
        }

        > ._poi {
            font-size: 14pt;
        }

        > ._address {
        }

        > ._location {
            font-size: 10pt;
            color: $grey-blue;
        }
    }
}

.ivms-etc-box {
    display: flex;

    > ._label {
        color: $grey-blue;
    }

    > ._value {
        margin-left: auto;
        font-size: 1.5rem;
    }
}

.imvs-poi {
    @apply flex flex-1;

    > ._map {
        flex: 1;
        position: relative;
    }

    > ._form {
        margin-right: 1rem;
        width: 400px;
        display: flex;
        flex-direction: column;
    }

    .un-list.-active {
        background-color: wcolor($blue, 25%);
    }
}

.ivms-poi-form {
    @apply border rounded border-blue-500 bg-blue-50 p-2 mb-4;

    .btn-save {
        width: 100%;
    }
}

.ivms-poi-form_type {
    @apply flex;
    .un-button {
        @apply px-0;
    }
}


.ivms-alarm-name-list {
    .input-checkbox {
        + .input-checkbox {
            margin-left: 1rem;
        }

        ._check-container {
            padding: 0;
        }
    }
}

.ivms-alarm-know {
    margin-top: .125rem;
    font-size: 9pt;
    line-height: 1.2;

    ul {
        margin-top: .125rem;
        margin-bottom: 0;

        li {
            color: $grey-blue;

            ._by {
                color: $grey;
                margin-left: .25rem;
            }

            ._time {
                color: $green;
                margin-left: .25rem;
            }
        }
    }
}

.ivms-alarm-detail {
    margin-top: .25rem;
    font-size: 10pt;
}


.ivms-alarm-answer {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: .5rem;
    border: 1px solid wcolor($grey-blue, 15%);
    border-radius: .25rem;

    > ._trailer {
        margin-right: auto;

        > ._name {
            font-size: 16pt;
        }

        > ._driver {
            color: $grey-blue;
        }
    }

    > ._alarm {
        text-align: right;

        > ._name {
            font-size: 16pt;
        }

        > ._sugg {
            color: $blue;
        }
    }
}

.ivms-alarm-report {
    &.-single {
        .htCore > thead > tr > th {
            vertical-align: bottom;

            &:nth-child(3) {
                .colHeader {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 9pt;
                    transform: rotate(-90deg) translateY(-80px) translateX(100px);
                    height: 20px;
                    width: 200px;
                    text-align: left;
                    line-height: 20px;
                    margin-top: 15px;
                }
            }
        }
    }

    .htCore > thead > tr > th {
        vertical-align: bottom;

        &:nth-child(n+4) {
            .colHeader {
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 9pt;
                transform: rotate(-90deg) translateY(-80px) translateX(100px);
                height: 20px;
                width: 200px;
                text-align: left;
                line-height: 20px;
                margin-top: 15px;
            }
        }
    }
}


.ivms-plan-from-header {
    @apply border;

    margin-top: 1rem;
    padding: .5rem;
    display: flex;
    align-items: center;

    > ._driver {
        > ._time {
            font-size: 10pt;
            color: $grey-blue;

            > span {
                margin-right: 1rem;
            }
        }
    }

    > ._trailer {
        margin-left: auto;
        font-size: 16pt;
    }
}

.ivms-plan-view {
    display: flex;

    > ._timebox {
        border: 1px solid wcolor($grey-blue, 50%);
        border-radius: .25rem;
        text-align: center;
        padding: .5rem;
        width: 80px;
        background-color: wcolor($grey-blue, 5%);

        + ._timebox {
            margin-left: 1rem;
        }

        > ._label {
            font-size: 10pt;
            line-height: 15px;
            margin-bottom: .25rem;
            color: $grey-blue;
        }

        > ._time {
            font-size: 14pt;
            line-height: 25px;
            font-weight: bold;
        }

        > ._date {
            font-size: 10pt;
            line-height: 15px;
            color: $grey;
        }

        > ._none {
            font-size: 10pt;
            line-height: 40px;
            color: $red;
        }
    }

    ._poi {
        > ._name {
            font-size: 16pt;
        }

        > ._address {
            font-size: 11pt;
            color: $grey-blue;
        }
    }

    ._trailer {
        text-align: right;

        > ._name {
            font-size: 16pt;
        }

        > ._driver {
            font-size: 11pt;
            color: $blue;
        }
    }

    ._note {
        font-size: 10pt;
        color: $green;
    }

    ._ps {
        font-size: 10pt;
        color: $grey-blue;

        > span {
            color: $red;
        }
    }
}

.pump-product-label {
    border-radius: .25rem;
    display: flex;
    color: $white;
    align-items: flex-end;
    padding: .25rem .25rem;
    height: 40px;

    > ._left {
        text-align: right;
        flex-grow: 1;
        margin-right: .25rem;

        > ._text {
            font-size: 10pt;
            line-height: 1;
        }

        > ._bottom {
            font-size: 7pt;
            line-height: 1;
            margin-bottom: .25rem;
        }
    }

    > ._center {
        font-size: 16pt;
        flex-grow: 1;
        margin-bottom: .5rem;
    }

    > ._big {
        font-size: 24pt;
        line-height: 1;
    }
}