@import "~unno-comutils/style/config";

$mainColor: $grey-blue;

.jobber-logo {
    margin: -5px 1rem -5px -.5rem;

    > img {
        height: 50px;
    }
}


.handsontable {
    .-down {
        color: $red !important;
        background-color: wcolor($red, 5%);
    }

    .-up {
        color: $green !important;
        background-color: wcolor($green, 5%);
    }
}