
.employee-box {
    height: 500px;
    display: flex;

    > ._search-box {
        display: flex;
        flex-direction: column;
        width: 50%;
        background: wcolor($mainColor, 5%);
        border-right: 1px solid wcolor($mainColor, 25%);

        > ._header {
            padding: .25rem;

            .input-text {
                background: $white;
            }
        }

        > ._list {
            flex-grow: 1;
            padding: 0 .25rem;
            overflow-y: auto;
        }
    }

    > ._selected-box {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > ._header {
            padding: .25rem;

            > ._title {
                background: wcolor($mainColor, 100%);
                border-radius: .25rem;
                margin-bottom: 0;
                padding: 0 1rem;
                line-height: 40px;
                font-size: 13pt;
                color: $white;
            }
        }

        > ._list {
            flex-grow: 1;
            padding: 0 .25rem;
            overflow-y: auto;
        }
    }
}

.employee-item {
    display: flex;
    padding: .5rem;
    margin-bottom: .25rem;
    background: $white;

    &.-selector:hover {
        cursor: pointer;
        border: 1px solid $orange;
        background: wcolor($orange, 5%);
    }

    > ._avatar {
        text-align: center;
        width: 1.5rem;
        margin-right: .5rem;
    }

    > ._title {
        flex-grow: 1;
    }


    .btn-drop {
        color: $red;
    }

    .un-icon-button {
        padding: 3px;

        i {
            width: 16px;
            text-align: center;
            font-size: 16px;
            line-height: 16px;
        }
    }
}


.employee-selector-wait {
    height: 500px;
    position: relative;
}

.employee-selector {
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-radius: 0.25rem;
    padding: .5rem;

    > ._control {
        display: flex;
        align-items: center;

        .input-select {
            max-width: 200px;
            margin-right: .5rem;
        }

        .input-text {
            max-width: 200px;
            margin-right: .5rem;
        }

        > .text-summary {
            margin-left: auto;
            margin-right: .5rem;
        }
    }

    > ._list {
        height: 500px;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.21);
        border-radius: 0.25rem;
        margin-top: .5rem;

        .item-list {
            border-top: 1px solid rgba(0, 0, 0, 0.21);

            &:first-child {
                border-top: none;
            }

            .icon-check {
                text-align: center;
                width: 20px;
                margin-right: .25rem;
                cursor: pointer;

                &:hover {
                    color: $red !important;
                }
            }

            .input-checkone {
                margin: -8px 0;
                padding: 6px;

                &:first-child {
                    margin-left: -8px;
                }
            }

            ._btn {
                display: flex;
                padding-right: 1rem;

                > ._name {
                    min-width: 200px;
                }
            }
        }
    }
}