
.ivms-trailer-panel {
    > ._list {
        @apply flex flex-col;
        width: 750px;

        .un-list-container {
            @apply flex-1;
            .un-list-head {
                @apply rounded-t py-1 text-xs;
            }

            &.-blue {
                @apply border-blue-500;
                .un-list-head {
                    @apply border-blue-500 bg-blue-50;
                }
            }

            &.-orange {
                @apply border-orange-500;
                .un-list-head {
                    @apply border-orange-500 bg-orange-50;
                }
            }
        }
    }

    > ._view {
        @apply ml-4 flex-1;

        .ivms-view-summary {
            margin-top: 0 !important;
        }
    }
}

@media screen and (max-width: 1440px) {
    .ivms-trailer-panel {
        > ._list {
            width: 375px;

            .un-input-checkbox ._control-input-label {
                @apply whitespace-nowrap;
            }
        }

        ._list-master {
            width: 100% !important;
        }

        ._list-slave {
            display: none !important;
        }
    }
}

.-blue .ivms-list:nth-of-type(even) {
    @apply bg-blue-50;
}

.-orange .ivms-list:nth-of-type(even) {
    @apply bg-orange-50;
}

.ivms-list.un-list {
    @apply text-sm py-1 cursor-pointer hover:bg-red-100;

    &.-active {
        @apply bg-red-100;
    }

    ._speed {
        @apply flex text-xs text-white py-0.5 px-1 mx-0 w-10 rounded opacity-80;
        .un-icon {
            @apply text-xs mr-auto;
        }
    }
}

.ivms-list-item {
    @apply block flex-1 py-1;
    margin: 0 !important;

    &._cover {
        padding: 0;
    }

    > ._item {
        @apply flex items-start m-0;

        &.-center {
            @apply items-center;
        }

        > div {
            @apply mx-1;
        }

        > ._icon {
            @apply w-8 text-center;
            > ._time {
                @apply text-blue-500;
            }
        }


        ._time {
            @apply leading-none text-xs;
        }

    }

    ._item-name {
        @apply flex items-center m-0 truncate;
        ._name {
            @apply leading-none ml-2;
        }

        ._driver {
            @apply text-xs leading-none text-red-500 ml-2;
        }
    }

    ._item-location {
        @apply mt-1 text-xs leading-none;
        ._address {
            @apply text-grey-blue-500;
        }

        ._origin {
            > .un-icon {
                @apply text-sm font-bold;
            }
        }
    }

    ._data-state {
        @apply px-1 rounded;

        > div {
            color: $white !important;
        }

        &.-red {
            @apply bg-red-500;
        }

        &.-blue {
            @apply bg-blue-500;
        }
    }
}

.ivms-view-header {
    > ._info {
        @apply flex;

        > ._btn-list-coll {
            display: none;
            margin-left: -.5rem !important;
            margin-right: 1rem !important;
        }

        > ._driver {
            @apply flex flex-1;
            > .un-icon {
                @apply text-4xl my-auto mr-2;
            }

            > ._text {
                @apply flex-1;
                ._name {
                    @apply text-lg;
                }

                ._tel {
                    @apply flex;
                }

                .un-button {
                    @apply py-0.5 px-1 text-xs bg-green-500 text-green-50 leading-none;
                    ._text {
                        @apply ml-1;
                    }

                    ._icon {
                        @apply text-xs;
                    }
                }
            }
        }

        > .un-button {
            @apply ml-1 px-0 w-8;
        }

        > ._trailer {
            @apply ml-2;
            > ._name {
                @apply text-xl;
            }

            > ._info {
                @apply flex items-center leading-none;
                > ._uid {
                    @apply text-xs text-grey-500;
                }

                > ._time {
                    @apply ml-2 text-xs text-grey-blue-500;
                }
            }
        }

        > ._ticket {
            @apply flex flex-col ml-4;

            &.-last {
                > ._shipment {
                    background-color: $grey-blue;
                }
            }

            ._info {
                @apply flex text-xs mt-1;
                ._status {
                    @apply text-center;
                }

                ._time {
                    @apply ml-2 text-center text-grey-blue-500;
                }
            }

            ._number {
                @apply bg-orange-500 text-white;
            }
        }
    }

    > ._route {
        @apply flex-1 mt-2;

        > ._item {
            @apply flex items-start;

            > .un-icon {
                @apply mr-2 w-4 text-center;
            }

            > ._text {
                @apply text-xs flex-1 leading-none;
            }

            .un-icon-button {
                @apply -my-2;
            }
        }
    }
}


@media screen and (max-width: 1024px) {
    .ivms-view-header > ._info > ._btn-list-coll {
        display: block !important;
    }

    .ivms-trailer-panel {
        > ._list {
            @apply absolute z-10 bg-white shadow-lg -inset-y-4 -left-4 p-2 hidden;
            &.-coll {
                @apply flex;
            }

            > ._btn-coll {
                @apply absolute z-10 m-1 bg-orange-500 text-white shadow-lg active:bg-orange-700;
                left: 100%;
            }
        }

        > ._view {
            margin: -0.5rem;
        }
    }
}

@media screen and (min-width: 1025px) {
    .ivms-trailer-panel {
        > ._list {
            > ._btn-coll {
                display: none !important;
            }
        }
    }
}
