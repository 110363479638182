.trouble-detail {
    border: 1px solid wcolor($grey, 30%);
    border-radius: .25rem;
    background-color: wcolor($grey, 5%);
    padding: .5rem;

    > ._head {
        display: flex;
        align-items: center;
        //margin-bottom: .75rem;

        > ._date {
            background-color: $grey-blue;
            padding: .25rem 1rem;
            color: $white;
            border-radius: .25rem;
        }

        > ._title {
            margin-left: 1rem;
            font-size: 14pt;
        }
    }

    > ._detail {
        font-size: 10pt;
        padding: .25rem 1rem;
    }
}


.checkup-list {
    ._topic {
        font-size: 14pt;
        margin-top: .5rem;
        margin-bottom: .5rem;
        color: $grey-blue;
    }
}

$checkup-item-state: ("ok":"green", "problem":"red", "wait":"orange");

.checkup-item {
    @apply border rounded-md p-2 mb-2;

    @each $name, $color in $checkup-item-state {

        &.-#{$name} {
            @apply border-#{$color}-500 bg-#{$color}-50 border-2;
            ._title {
                @apply text-#{$color}-500;
            }
        }
    }

    > ._head {
        display: flex;
        align-items: center;

        > ._title {
            display: flex;
            align-items: flex-start;
            margin: .25rem auto .25rem 0;

            > ._rownum {
                margin-right: .5rem;
                width: 30px;
                text-align: right;
            }

            > ._text {
                flex: 1;
            }
        }
    }


    > ._problem {
        @apply border;
        background-color: $white;
        padding: .5rem;
        margin-top: .5rem;

        > ._date {
            margin-bottom: .25rem;
            color: $grey-blue;
            font-size: 10.5pt;
        }

        > ._detail {
            font-size: 10.5pt;
        }
    }
}
