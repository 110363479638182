.modal.show {
    display: block !important;
}

.modal {
    &.-xl {
        .modal-dialog {
            max-width: 80%;
        }
    }
}
