.un-list {
    .btn-block.btn-sm {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    &.-add {
        background-color: wcolor($green, 10%);
    }

    &.-del {
        background-color: wcolor($red, 10%);
    }
}

.form-header {
    margin-bottom: 0;
    font-size: 12pt;
    color: $grey-blue;

    display: flex;
    width: 100%;
    text-align: center;

    &:after, &:before {
        content: "";
        border-bottom: 1px solid wcolor($grey-blue, 25%);
        margin-bottom: .375rem;
    }

    &:before {
        width: 50px;
    }

    &:after {
        flex: 1 1;
    }

    > span {
        margin: 0 .5rem;
    }
}