.report-list {
    > h3 {
        @apply text-2xl my-4 text-center text-grey-blue-500;
    }

    .row {
        @apply flex-wrap;
    }
}

.report-item {
    @apply flex border rounded my-3 py-1 h-16 items-center text-center justify-center text-lg leading-tight hover:shadow-lg hover:text-orange-500;
}

.report-selector {
    @apply p-1 w-60;
    > ._item {
        @apply block text-base py-2 px-4 hover:text-red-500;
        &.-active {
            @apply bg-grey-blue-50;
        }
    }
}

.handsontable {
    .-sum {
        &.-name {
            text-align: right;
        }

        font-weight: bold;
        font-size: .85rem !important;
        background-color: wcolor($grey-blue, 15%);
    }
}

.report-menu {
    .un-dropdown-menu {
        @apply p-2;
        width: 800px;

        .un-wait {
            @apply relative my-2 mx-auto top-0 left-0;
        }
    }

    ._report-group {
        @apply py-1 px-2 text-grey-blue-500;
    }

    ._report-items {
        @apply flex flex-wrap;
    }

    ._report-item {
        @apply block w-1/3 p-2 rounded;

        > .un-icon {
            @apply mx-2;
        }

        &:hover {
            color: $mainColor;
            background-color: wcolor($mainColor, 5%);
        }

        &.-active {
            cursor: default;
            color: bcolor($mainColor, 75%);
            background-color: wcolor($mainColor, 10%);
        }
    }
}

.report-progressbar {
    padding: 5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > .un-icon {
        font-size: 10rem;
    }

    > .wait {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        height: 10rem;
        margin: 0;
        opacity: 0.5;
    }

    .CircularProgressbar {
        width: 40%;
        vertical-align: middle;
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: #3e98c7;
        stroke-linecap: round;
        -webkit-transition: stroke-dashoffset 0.5s ease 0s;
        transition: stroke-dashoffset 0.5s ease 0s;
    }

    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #d6d6d6;
        stroke-linecap: round;
    }

    .CircularProgressbar .CircularProgressbar-text {
        fill: #3e98c7;
        font-size: 24px;
        dominant-baseline: central;
        text-anchor: middle;
    }

    .CircularProgressbar .CircularProgressbar-background {
        fill: #d6d6d6;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
        fill: #3e98c7;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
        fill: #fff;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
        stroke: #fff;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
        stroke: transparent;
    }

}

.report-chart {
    @apply border rounded;
    padding: .125rem;

    &.flex-grow-1 {
        flex: 1;
    }
}

.report-chart-option {
    @apply flex mt-4 items-center;
}

.report-table {
    &.-full {
        @apply -mx-4 -mb-3;
    }

    &.-nested {
        .ht_nestingParent {
            ~ td {
                @apply font-bold text-grey-blue-700 bg-grey-blue-50;
            }

            > .relative {
                @apply text-center text-white;
                text-indent: 0;

                .rowHeader {
                    display: none;
                }

                .ht_nestingButton {
                    @apply w-full text-lg leading-tight;
                    color: white;
                }
            }
        }
    }

    thead {
        tr {
            th, th > div, th > .relative {
                padding: 0;
                height: .375rem !important;
                background-color: wcolor($grey-blue, 75%) !important;
                color: wcolor($grey-blue, 75%);
                text-indent: -9000px;
            }
        }
    }

    tbody {
        tr {
            th, th > div, th > .relative {
                padding: 0;
                width: .375rem !important;
                background-color: wcolor($grey-blue, 75%) !important;
                color: wcolor($grey-blue, 75%);
                text-indent: -9000px;
            }
        }
    }

    td {
        &.-header, &.-header ~ td {
            vertical-align: bottom;
            background-color: wcolor($grey-blue, 25%);

            &.c {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        &.-group {
            text-align: left !important;
            font-weight: bold;
            background-color: wcolor($grey-blue, 15%);
        }
    }
}

.report-table-limit {
    @apply border rounded;
    padding: .125rem;
    height: 400px;
    display: flex;
}

.report-summary {
    display: flex;

    + .report-summary {
        border-top: 1px solid wcolor($grey, 25%);
        margin-top: 1.5rem;
        padding-top: 1.5rem;
    }

    > ._header {
        width: 300px;

        > .un-icon {
            font-size: 2rem;
            width: 3rem;
            text-align: center;
        }

        > ._text {
            font-size: 18pt;
        }
    }

    > ._data {
        flex: 1;
        margin-top: .5rem;

        .detail-item {
            > ._label {
                width: 180px;
                max-width: 180px;
            }
        }

        ._unit {
            margin: 0 .5rem;
            color: $grey;
        }
    }
}

.report-summary-header {
    margin-top: 2rem;
    margin-bottom: 0;

    > .un-icon {
        font-size: 1.5rem;
        width: 3rem;
        text-align: center;
    }
}

.report-workload {
    @apply relative flex flex-col border rounded;
    ._name {
        @apply w-36 px-2;
    }

    ._date {
        @apply w-24 px-2 mx-0 text-right;
    }

    .un-list-head {
        @apply flex py-1 mx-px;
        color: #9e9e9e;
        border-bottom: 1px dashed #d8d8d8;

        > ._hs {
            @apply flex flex-1 mx-0;
            > ._h {
                flex: 1;
                text-align: center;
            }
        }

        .-with-line {
            > ._line {
                position: absolute;
                top: 1px;
                bottom: 1px;
                width: 5px;
                border-left: 1px dashed wcolor($grey, 50%);
            }
        }
    }

    > .un-list-body {
        position: relative;
        flex: 1;
    }

    ._device {
        @apply flex border-b py-0;

        ._name {
            @apply py-1.5 px-2;
        }

        > ._data {
            @apply flex-1 mx-0 text-grey-blue-500;

            > ._day {
                @apply flex;

                &:nth-child(even) {
                    @apply bg-grey-blue-50 bg-opacity-50;
                }

                &.-total {
                    @apply flex-1 mx-0 text-black border-t bg-transparent border-grey-blue-50;
                }


                ._date, ._total {
                    @apply py-1.5 px-2;
                }

                ._total {
                    @apply ml-auto font-bold;
                }

                > ._time {
                    @apply relative flex-1;
                    > ._item {
                        position: absolute;
                        top: 5px;
                        bottom: 5px;
                        min-width: 4px;
                        background-color: $orange;
                        border-radius: .125rem;
                        opacity: .8;

                        &.-left {
                            left: 0;
                        }

                        &.-right {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}

.report-chart-location-name {
    margin-top: .5rem;
    padding-top: .5rem;
    border-top: 1px solid wcolor($grey-blue, 50%);
    width: 200px;
    color: $grey-blue;
    height: 60px;
    white-space: normal;
}

