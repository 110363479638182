
.trailer-tire {
    @apply flex mb-12 mt-6 mx-6 h-60;

    &.-vertical {
        @apply flex-col h-auto w-60 mx-auto;
        > ._head {
            @apply flex-col rounded-t-3xl rounded-b-xl;
            > ._deco {
                @apply flex-col;
                > ._light {
                    @apply flex-row;
                    > ._item {
                        @apply w-16 h-8 rounded-full;
                    }
                }

                > ._grs {
                    @apply h-12 w-auto ml-0 mt-6;
                }
            }
        }

        > ._tail {
            @apply flex-col mt-8 ml-0;
            ._tire {
                @apply my-4 -mx-16;
                &.-ml {
                    @apply mt-20 -mx-16;
                }
            }
        }

        ._tire {
            @apply flex-row-reverse my-4 -mx-16;
        }
    }

    > ._head {
        @apply flex border rounded-l-3xl rounded-r-xl;
        > ._deco {
            @apply flex p-2;
            > ._light {
                @apply flex flex-col justify-between;
                > ._item {
                    @apply bg-grey-blue-100 w-8 h-16 rounded-full;
                }
            }

            > ._grs {
                @apply bg-grey-blue-200 w-12 rounded-xl ml-6;
            }
        }

        ._tire + ._tire.-ml {
            @apply ml-8;
        }

        + ._tail {
            @apply flex-1 ml-8 pl-0;
        }
    }

    > ._head-connect {
        @apply w-10 h-10 bg-grey-blue-50 border border-l-0 rounded-r-3xl my-auto;
    }

    > ._tail-connect {
        @apply w-10 h-10 bg-grey-blue-50 border border-r-0 rounded-l-3xl my-auto ml-auto;
        + ._tail {
            @apply ml-0;
        }
    }

    > ._tail {
        @apply flex border rounded-xl ml-auto pl-24;
    }

    ._tire {
        @apply flex flex-col justify-between mx-4 -my-4;
        &.-ml {
            @apply ml-auto;
        }

        + ._tire {
            @apply ml-0;
        }

        ._item {
            @apply flex border w-40 p-0.5 rounded-md items-center bg-white;

            &.-mt {
                margin-top: auto !important;
            }

            + ._item {
                @apply mt-2;
            }

            > ._number {
                @apply py-3 w-8 text-center bg-grey-blue-500 rounded text-white;
            }

            > ._select {
                @apply bg-orange-500;
            }

            > ._info {
                @apply ml-2;
            }
        }
    }
}

.logistic-plan-raw {
    font-size: .7rem;
}

.logistic-plan-trailer {
    ._address {
        font-size: .7rem;
        color: $grey-blue;
    }
}

.logistic-plan-employees {
    &.-selected {
        .logistic-plan-employee {
            @apply border-blue-500 bg-blue-50 hover:ring-blue-500 hover:ring-opacity-20;
        }
    }

    &.-wait {
        @apply border border-dashed rounded-lg p-2 mt-6;
        height: 400px;
    }
}

.logistic-plan-employee {
    @apply flex border rounded p-1 cursor-pointer hover:ring-2 hover:ring-orange-500 hover:ring-opacity-20;

    + .logistic-plan-employee {
        @apply mt-2;
    }

    > ._img {
        @apply w-14 h-14 rounded;
    }

    > ._icon {
        @apply w-14 h-14 flex items-center justify-center text-2xl text-grey-blue-200;
    }

    > ._detail {
        @apply flex-1 ml-2 pt-1;
        > ._name {
            @apply mb-2;
        }

        > ._time {
            @apply pl-2 pr-4;
        }
    }
}

.logistic-history {
    @apply border;

    + .logistic-history {
        margin-top: .5rem;
    }

    padding: .25rem;

    > ._header {
        display: flex;
        align-items: flex-start;

        > ._date {
            background-color: $grey-blue;
            min-width: 50px;
            max-width: 50px;
            text-align: center;
            color: $white;
            padding: .25rem 0;
            border-radius: .25rem;

            > ._day {
                font-size: 14pt;
                line-height: 20px;
            }

            > ._month {
                font-size: .7rem;
                line-height: 16px;
            }
        }
    }

    > ._nodes {
        margin-top: .5rem;

        > ._node {
            display: flex;
            align-items: flex-start;
            margin-bottom: .25rem;

            &.-last {
                > .un-icon {
                    color: $red;
                }

                > ._name {
                    font-weight: bold;
                    color: $red;
                }
            }

            > .un-icon {
                margin: 0 .25rem;
                color: $grey-blue;
            }

            > ._name {
                color: $grey;
                margin-left: .5rem;
                font-size: 10pt;
                line-height: 1;
            }
        }
    }
}


.logistic-invoice-node {
    background-color: $grey-blue;
    color: $white;
    border-radius: .25rem;
    padding: .5rem 1rem;

    > ._name {
        font-size: 14pt;
    }

    > ._address {
        font-size: 10pt;
    }
}

.logistic-invoice-nodes {
    @apply border;
    padding: .25rem;

    > ._item {
        border-radius: .25rem;

        &:hover {
            background-color: wcolor($grey-blue, 5%);
        }

        display: flex;
        align-items: center;

        > ._detail {
            flex: 1;
            padding: .25rem;

            > ._address {
                font-size: 9pt;
                color: $grey-blue;
            }
        }
    }
}


.logistic-route-view-modal {
    .un-modal-title {
        @apply flex-1;
        .un-modal-title-text {
            @apply flex truncate ml-2;

            .un-icon {
                @apply mx-3;
            }
        }
    }

    .un-modal-header-ext {
        @apply flex-none;
    }


    > .un-modal-body {
        ._card {
            @apply flex items-center p-2 mb-4 border rounded border-green-500 bg-green-50;
            > ._title {
                @apply text-xl text-green-500 mx-4;
            }

            > ._label {
                @apply mx-4;
            }
        }

        ._map-info {
            @apply flex mt-3 items-center;
        }

        ._pois {
            @apply w-1/3 flex flex-col;
        }

        ._route-step {
            @apply flex-none w-1/4 h-96;
        }

        ._route-time {
            @apply ml-4 w-full h-96;
        }
    }
}