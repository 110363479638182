.pttimport-summary {
    .htCore {
        > thead {
            > tr {
                > th {
                    vertical-align: bottom;

                    &:nth-child(n+7) {
                        .colHeader {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            font-size: 9pt;
                            transform: rotate(-90deg) translateY(-80px) translateX(100px);
                            height: 20px;
                            width: 200px;
                            text-align: left;
                            line-height: 20px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}

.pttimport-table {
    .htCore {
        > thead {
            > tr {
                > th {
                    vertical-align: bottom;

                    &:nth-child(n+3) {
                        .colHeader {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            font-size: 9pt;
                            transform: rotate(-90deg) translateY(-80px) translateX(100px);
                            height: 20px;
                            width: 200px;
                            text-align: left;
                            line-height: 20px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}


.routecard-oilpool {
    @apply border;
    padding: .5rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    background-color: wcolor($grey-blue, 15%);

    > ._number {
        width: 40px;
        text-align: center;
        margin-right: .5rem;
        color: $grey-blue;
    }

    > .input-select {
        width: 250px;
        margin-right: .5rem;
    }

    > .input-text {

    }
}


.appraise-risk-item-header {
    @apply border;
    padding: .25rem .75rem;
    background-color: wcolor($grey-blue, 5%);

    > ._title {
        font-weight: bold;
        color: $grey-blue;
    }

    > ._detail {
        flex: 1;
        font-size: .8rem;
        color: $grey;
    }
}

.appraise-risk-score {
    @apply -m-4;
    height: 700px;

    ._header {
        @apply py-2 px-4 bg-grey-blue-200 font-bold;
        .n {
            @apply font-normal;
        }
    }

    ._sub-header {
        @apply py-1 px-4 text-sm bg-grey-blue-100;
    }

    ._item {
        @apply flex items-start p-2;
        + ._item {
            @apply border-t mt-4 pt-6;
        }

        ._title {
            @apply flex w-60;
            > ._rn {
                @apply w-10 mr-2 text-right;
            }

            > ._text {
                @apply flex-1;
            }
        }

        ._rows {
            @apply flex-1;
        }
    }

    ._row {
        @apply flex items-start;
        + ._row {
            @apply mt-2;
        }

        ._label {
            @apply w-40 pt-2 pr-3 text-right text-grey-blue-500;
        }

        ._control, > ._input-f {
            flex: 1;
        }

        ._input-r {
            width: 230px;
        }

        ._score {
            @apply flex;
            width: 300px;

            ._score-rpn {
                @apply border p-2 rounded bg-grey-blue-50 ml-2;
                min-width: 70px;
            }

            ._score-hl {
                @apply border p-2 w-20 text-center font-bold rounded-full border-grey-blue-100 bg-grey-blue-50 text-grey-blue-500 ml-2;
                &.-h {
                    @apply border-red-600 bg-red-500 text-red-100;
                }

                &.-l {
                    @apply border-green-600 bg-green-500 text-green-100;
                }
            }
        }

        ._event {
            + ._event {
                margin-top: .25rem;
            }

            display: flex;
        }

        ._check-container {
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }

    ._btn-remove {
        @apply mt-1 ml-2;
    }

    .detail-item {
        margin-top: 0;
        font-size: .8rem;
    }

    textarea {
        min-height: 22px !important;
    }
}