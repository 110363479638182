.expires-table {
    @apply border-t-0;
    &.-first {
        @apply border-t;
    }

    &:first-child {
        @apply border-t-0;
    }

    &:last-child {
        @apply border-b;
    }
}


.card-border {
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-radius: .25rem;
}

.iframe {
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-radius: .25rem;
    width: 100%;
    height: 100%;

    &.-w600 {
        max-width: 600px;
    }
}

.div-click {
    @apply cursor-pointer hover:text-red-500;
}

.radio-center {
    @apply flex items-center justify-center;
    .un-input-radio-item {
        @apply mr-0;
    }
}

.photo-stack {
    padding: .125rem;
    border: 1px solid wcolor($grey, 25%);
    border-radius: .25rem;
    margin: 1rem 0;
    display: flex;

    > ._item {
        @apply border rounded m-1 w-20 h-20 bg-cover bg-center cursor-pointer hover:border-orange-500;
    }
}

.ql-editor {
    min-height: 200px;
}


.timebox {
    border: 1px solid wcolor($grey-blue, 50%);
    border-radius: .25rem;
    text-align: center;
    padding: .25rem .5rem;
    margin-right: 1rem;
    background-color: wcolor($grey-blue, 5%);

    > ._time {
        font-size: 14pt;
        font-weight: bold;
    }

    > ._date {
        font-size: 10pt;
        color: $grey-blue;
    }
}

.pill-count {
    background-color: $red;
    color: $white;
    border-radius: .5rem;
    font-size: 7pt;
    line-height: 1;
    padding: 0 .25rem;
}

.un-list-alert {
    background-color: wcolor($pink, 8%);
}


.detail-photo {
    + .detail-photo {
        margin-top: 1rem;
    }

    @apply border;
    width: 120px;
    height: 150px;
    background-size: cover;
    background-position: center;

    &.-button {
        cursor: pointer;

        &:hover {
            border-color: wcolor($blue, 50);
            box-shadow: 0 0 0 0.2rem wcolor($blue, 25);
        }
    }
}

.detail-item {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;

    > ._label {
        width: 160px;
        text-align: right;
        padding-right: 1rem;
        color: $grey-blue;

        &.-xs {
            width: 50px;
        }

        &.-sm {
            width: 80px;
        }
    }

    > ._value {
        flex-grow: 1;
    }

    ol {
        margin-bottom: 0;
    }
}


.un-list-green {
    background-color: wcolor($green, 10%);
}

.un-list-red {
    background-color: wcolor($red, 10%);
}

.un-list-blue {
    background-color: wcolor($blue, 10%);
}


.list-sort {
    @apply cursor-pointer;
    &.-active {
        @apply text-black relative;

        &:before {
            @apply block absolute left-1;
            font-family: "Font Awesome 5 Pro";
            font-weight: 700;
            content: "\f0de";
        }

        &.-desc:before {
            content: "\f0dd";
        }
    }

    &:hover {
        @apply text-red-500;
    }
}


.un-list-body {
    > .mock {
        @apply m-2;
    }
}