$sideWidth: 250px;
$appbarMargin: 24px;

@mixin layoutColor($c) {
    .layout-mainmenu {
        @apply bg-#{$c}-500;

        .un-icon-button {
            @apply text-#{$c}-200 hover:text-#{$c}-400 hover:bg-#{$c}-50;
        }
    }

    .layout-menuifo {
        @apply bg-#{$c}-700 text-#{$c}-100;
    }

    .layout-sidemenu {
        @apply bg-#{$c}-50;

        &.-mini {
            .layout-mainmenu {
                > ._user {
                    @apply bg-#{$c}-600;
                }
            }
        }
    }

    .layout-menu {
        > ._protect {
            .layout-menu-div {
                @apply border-#{$c}-300;
            }

            .layout-menu-header {
                @apply text-#{$c}-700;
            }

            .btn-coll > .un-icon-button {
                @apply text-#{$c}-500;
            }
        }
    }

    .layout-menu-item {
        @apply text-#{$c}-600;

        &:hover {
            @apply bg-#{$c}-200 text-#{$c}-700;
        }

        &.-active {
            @apply bg-#{$c}-500 text-#{$c}-50;
        }
    }

    .un-menubar {
        @apply bg-#{$c}-100;
    }

    .un-menubar-item {
        @apply text-#{$c}-400 hover:text-#{$c}-700 hover:bg-#{$c}-200;

        &:before {
            @apply bg-#{$c}-700;
        }

        &.-active {
            @apply bg-#{$c}-400 text-white;
        }
    }
}


.layout {
    display: flex;
    flex-direction: row;
    flex: 1;

    &.site_main {
        @include layoutColor('grey-blue');
    }

    &.site_north {
        @include layoutColor('green');
    }
}

.layout-mainmenu {
    @apply flex relative h-12 px-2 items-center;
    background-color: $mainColor;

    ._container {
        @apply flex flex-1;
    }

    .un-icon {
        @apply w-10 h-10 text-center leading-10;
        &.-menu {
            display: none;
        }
    }

    > .un-icon {
        @apply text-white;
    }
}

.layout-sysmenu {
    @apply pt-2;

    &.-popup {
        @apply absolute border rounded-md shadow-lg bg-white p-1 w-64 top-14 z-40;
        &:before {
            @apply absolute w-4 h-4 top-0 -mt-2 left-12 bg-white border border-b-transparent border-r-transparent rotate-45;
            content: ' ';
        }
    }

    ._menu {
        padding: .5rem .75rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: wcolor($blue, 10%);
        }

        &.-active {
            background-color: wcolor($blue, 15%);
            border-radius: .25rem;
        }

        & + .button-list {
            margin-top: .125rem;
        }

        .un-icon {
            font-size: 32px;
            width: 2.5rem;
            margin-right: .75rem;
        }

        ._text {
            font-size: 11pt !important;
        }
    }
}

.layout-menuifo {
    @apply flex px-2 py-1 text-xs;
    background-color: bcolor($mainColor, 85%);
    color: wcolor($mainColor, 25%);

    > ._user {
        @apply ml-auto;
    }
}

/*
.layout-sidemenu {
    @apply flex flex-col w-60;
    background: wcolor($mainColor, 15%);

    &.-grow {
        @apply flex-1;
    }

    &.-mini {
        @apply w-12;
        .layout-mainmenu {
            padding: 0;
            justify-content: center;
        }

        ._no-mini-show, .layout-menuifo {
            display: none;
        }

        .layout-sysmenu {
            > ._menu {
                @apply px-0 py-2 text-center;
                > .un-icon {
                    @apply text-2xl my-0 mx-auto;
                }

                > ._text {
                    @apply hidden;
                }
            }
        }

        .layout-menu {
            overflow: visible;

            > ._protect {
                overflow: visible;
            }

            ._group {
                + ._group {
                    @apply pt-2;
                    border-top: 1px dashed wcolor($mainColor, 50%);
                }

                > ._title {
                    display: none;
                }

                > ._menu {
                    @apply block relative py-1 px-0 text-center;

                    &:hover {
                        > span {
                            @apply flex;
                        }
                    }

                    > .un-icon {
                        @apply text-2xl my-0 mx-auto;
                    }

                    > span {
                        @apply absolute hidden shadow inset-y-0 py-0 px-2 z-20 items-center w-48;
                        left: 100%;
                        background-color: wcolor($mainColor, 20%);
                    }
                }
            }
        }
    }
}
*/


.layout-sidemenu {
    @apply flex flex-col w-60 bg-grey-100;

    &.-grow {
        @apply flex-1;
    }

    &.-mini {
        @apply w-12;

        .layout-mainmenu {
            @apply p-0 justify-center text-center;
            &:hover {
                @apply relative;
            }

            .menumain-btn {
                @apply flex items-center justify-center;
            }
        }

        ._no-mini-show, .layout-menuifo {
            display: none;
        }

        .layout-menu {
            @apply overflow-visible pt-2;

            > ._protect {
                @apply overflow-visible;
            }

            .layout-menu-div {
                @apply mt-2 mx-1;
            }

            .layout-menu-header {
                @apply hidden;
            }

            .layout-menu-item {
                @apply relative block py-1.5 px-0 text-center;

                > .un-icon {
                    @apply m-0 text-xl;
                }

                > ._text {
                    @apply hidden;
                }
            }
        }

        .layout-menu-extra {
            @apply mx-0.5 -mt-1.5;
        }
    }
}

.layout-menu-item {
    @apply flex items-center py-1 px-4 justify-start;
    .un-icon {
        @apply text-center w-6 text-lg mr-2;
    }

    &.-active {
        .un-icon {
            color: white !important;
        }
    }
}


.menumain-dropdown {
    @apply z-50 border-grey-blue-900 bg-grey-blue-800 rounded-lg shadow-2xl overflow-auto px-3 pb-3;
    width: 640px;
    max-width: 98%;
    max-height: 90vh;
}

.menumain-btn {
    @apply flex items-center text-2xl opacity-75 text-white p-1.5 cursor-pointer hover:opacity-100;
}

.menumain-group-core {
    @apply mt-2 -mx-1 mb-6 p-1 flex bg-grey-blue-900 rounded-lg;
    .menumain-item {
        @apply flex-1;
        &.-active > ._box {
            @apply bg-grey-blue-600;
        }

        &.-fav_active > ._box {
            @apply ring-1 ring-opacity-25 ring-orange-500;
        }
    }
}

.menumain-group_header {
    @apply w-full border-b border-dashed border-grey-blue-200 mt-3 mb-3;
    line-height: 0.1rem;

    span {
        @apply bg-grey-blue-800 text-grey-blue-50 px-2 ml-10;
    }
}

.menumain-group_item {
    @apply flex pl-4;
}

.menumain-item_label {
    @apply w-28 py-2 text-sm text-grey-blue-200;
}

.menumain-items {
    @apply flex flex-1 flex-wrap;
    .menumain-item {
        @apply w-1/3;
    }
}

.menumain-item-next {
    @apply w-full;
}

.menumain-item {
    @apply p-1 text-grey-blue-100;

    &:hover {
        > ._box {
            @apply bg-grey-blue-100 text-grey-blue-700;
        }

        ._icon-fav {
            @apply block;
        }
    }

    &.-active > ._box {
        @apply bg-grey-blue-600;
    }

    &.-active:hover > ._box {
        @apply text-white;
    }

    &.-fav_active > ._box {
        @apply ring-1 ring-opacity-25 ring-grey-blue-900;
        ._icon-fav {
            @apply block font-bold text-orange-400;
        }
    }

    &.-fav_active.-active > ._box {
        @apply shadow-grey-blue-800;
    }

    > ._box {
        @apply flex items-center rounded py-1 px-3 ;
    }

    ._link {
        @apply flex flex-1 items-center;
    }

    ._icon {
        @apply w-10 text-2xl;
    }

    ._text {
        @apply flex-1 truncate;
    }

    ._icon-fav {
        @apply hidden cursor-pointer hover:text-orange-500;
    }
}


.layout-menu {
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    > ._protect {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;

        ._group {
            margin-top: .5rem;

            > ._title {
                padding: .25rem 1rem;
                font-size: 9pt;
                margin-bottom: 0;
                text-align: right;
                text-transform: uppercase;
                font-weight: bold;
                color: $mainColor;
            }

            ._menu {
                display: flex;
                align-items: center;
                padding: .5rem 1rem;
                justify-content: flex-start;
                border-radius: 0;
                color: $mainColor;

                &:hover {
                    background-color: wcolor($mainColor, 35%);
                    color: bcolor($mainColor, 85%);
                }

                &.-active {
                    background-color: wcolor($mainColor, 25%);
                    color: bcolor($mainColor, 55%);
                }

                .un-icon {
                    text-align: center;
                    width: 1.5em;
                    font-size: 24px;
                    margin-right: .5rem;
                }

                ._text {
                    font-size: 11pt;
                }
            }

            ._subitem {
                > div {
                    padding-left: 3.5rem;
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                }

                ._item-text {
                    span {
                        font-size: 11pt;
                    }
                }
            }
        }

        .btn-coll {
            text-align: center;
        }
    }
}

.layout-content {
    > ._protect {
        @apply flex flex-col absolute inset-0 overflow-auto p-4;
        .un-menubar.-fix-top {
            @apply border-none rounded-none h-12 pl-4;
            .un-menubar-item {
                @apply pt-1;
            }
        }
    }
}

.flex-break {
    flex-basis: 100%;
    height: .5rem;
}

@include mobile() {
    .menumain-items {
        .menumain-item {
            @apply w-1/2;
        }
    }
}

@media only screen and (max-width: 1600px) {
    .hidden-1 {
        display: none !important;
    }
}

@media only screen and (max-width: 1200px) {
    .hidden-2 {
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .hidden-3 {
        display: none !important;
    }
}