.group-roles {
    @apply border rounded;
    > .un-form-container {
        @apply p-2;
        .un-form-body {
            @apply mt-0 py-2 pr-6;
        }
    }

    ul {
        @apply mb-0 pl-8;
    }
}

.group-role-item {
    @apply flex m-1 py-1 px-2 rounded text-grey-500 cursor-pointer;
    &:hover {
        @apply bg-orange-100 text-orange-500;
        > ._icon:before {
            content: "\f055";
        }
    }

    &.-active {
        @apply bg-green-100 text-green-500;
        &:hover {
            @apply bg-red-100 text-red-500;
            > ._icon {
                font-weight: 300;

                &:before {
                    content: "\f057";
                }
            }
        }

        > ._icon {
            font-weight: 900;

            &:before {
                content: "\f058";
            }
        }
    }

    ._icon {
        @apply flex items-center justify-center w-6 h-6 mr-1;
    }
}
