.un-list-head {
    > .un-button {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 0 !important;
        padding: .375rem .75rem .25rem .75rem !important;
    }
}

.un-form-container {
    @apply pb-0;
    &.-collapse-close {
        @apply pb-6;
    }

    &.-vertical .un-form-body {
        @apply mt-6;
    }

    &.-first, &:first-child {
        @apply pt-0;
    }
}
